import DatePicker from 'components/Forms/DatePicker/DatePicker';
import Decimal from 'components/Forms/Decimal/Decimal';
import Draw from 'components/Forms/Draw/Draw';
import FieldCheckbox from 'components/Forms/FieldCheckbox/FieldCheckbox';
import Geolocation from 'components/Forms/Geolocation/Geolocation';
import RadioBox from 'components/Forms/RadioBox/RadioBox';
import Signature from 'components/Forms/Signature/Signature';
import TextField from 'components/Forms/TextField/TextField';
import UploadImage from 'components/Forms/UploadImage/UploadImage';
import { Field, Item } from 'store/states/forms/types';

interface FormSelectorProps {
  field: Field;
  value: string;
  setValue: (value: string) => void;
  onCustomItemAdded: (items: Item) => void;
  onCustomItemRemoved: (items: Item) => void;
}

export default function FormSelector({
  field,
  value,
  setValue,
  onCustomItemAdded,
  onCustomItemRemoved,
}: FormSelectorProps) {
  const setMultiplesValues = (newValues: string[]) => {
    setValue(newValues.join('\t'));
  };

  const getMultipleValues = () => {
    if (value === '') return [];
    return value.split('\t');
  };

  switch (field.type) {
    case 'text':
      return <TextField setValue={setValue} defaultValue={value} />;
    case 'textArea':
      return (
        <TextField
          label={field.summary_title}
          isExtraAnnotation
          setValue={setValue}
          defaultValue={value}
        />
      );
    case 'number':
      return (
        <TextField
          label={field.summary_title}
          setValue={setValue}
          defaultValue={value}
          type="number"
        />
      );
    case 'radio': {
      return (
        <RadioBox
          items={field.items?.map((item) => ({
            value: item.value?.toString() || item.name?.toString(),
            label: item.label || item.name?.toString(),
          }))}
          setValue={setValue}
          defaultValue={value}
        />
      );
    }

    case 'date':
      return (
        <DatePicker
          label={field.summary_title}
          setValue={setValue}
          defaultValue={value}
        />
      );
    case 'image':
      return <UploadImage setValue={setValue} defaultValue={value} />;
    case 'geolocation':
      return <Geolocation setValue={setValue} defaultValue={value} />;
    case 'draw':
      return <Draw setValue={setValue} defaultValue={value} />;
    case 'signature':
      return <Signature setValue={setValue} defaultValue={value} />;
    case 'decimal':
      return <Decimal setValue={setValue} defaultValue={value} />;
    case 'checkbox':
      return (
        <FieldCheckbox
          field={field}
          setMultipleValues={setMultiplesValues}
          defaultValues={getMultipleValues()}
          onCustomItemAdded={onCustomItemAdded}
          onCustomItemRemoved={onCustomItemRemoved}
        />
      );
    default:
      return (
        <div style={{ color: 'black' }}>
          Error &nbsp;
          {field.type}
        </div>
      );
  }
}
