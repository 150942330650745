import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { Field, FormState } from 'store/states/forms/types';
import { formatTimestampDate } from 'utils/fieldNotebookUtils/formatters';
import openFullIcon from 'assets/icons/open_full.svg';
import { Input } from '../Input/Input';
import { InputDate } from '../InputDate/InputDate';
import { ThumbnailImage } from '../ThumbnailImage/ThumbnailImage';
import { MapsAnswer } from '../MapsAnswer/MapsAnswer';
import { ModalOpener } from './styled';

interface HistoricInputSelectorProps {
  field: Field;
  answer?: string | null;
  formDate: number;
  openImageModal: (imageurl: string, title: string, subtitle: string) => void;
  openThumbnailModal: (
    signature: string,
    title: string,
    subtitle: string,
  ) => void;
  openDrawModal: (draw: string, title: string, subtitle: string) => void;
}

export function HistoricInputSelector({
  field,
  answer,
  formDate,
  openImageModal,
  openThumbnailModal,
  openDrawModal,
}: HistoricInputSelectorProps) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  const { selectedForm } = useSelector<ReduxState, FormState>(
    (state) => state.forms,
  );
  const formattedDate = formatTimestampDate(formDate, locale);

  const isEmpty = answer === '' || answer === null || answer === undefined;

  if (isEmpty) {
    return (
      <Input color="#767676" weight={400}>
        {t('unanswered')}
      </Input>
    );
  }

  switch (field.type) {
    case 'date': {
      return <InputDate date={answer} />;
    }

    case 'image': {
      return (
        <ThumbnailImage
          image={answer}
          onClick={() =>
            openImageModal(
              answer,
              t('image_of_your_note'),
              `${selectedForm.title}: ${formattedDate}`,
            )
          }
        >
          <div>
            <p>{t('tap_to_full_screen')}</p>
            <img src={openFullIcon} alt="open full icon" />
          </div>
        </ThumbnailImage>
      );
    }
    case 'geolocation': {
      const coordinates = answer.split(',');
      return (
        <MapsAnswer
          lat={coordinates[0]}
          long={coordinates[1]}
          subtitle={`${selectedForm.title}: ${formattedDate}`}
        />
      );
    }
    case 'signature': {
      return (
        <ModalOpener
          image={answer}
          onClick={() =>
            openThumbnailModal(
              answer,
              `${t('show_signature_modal_title')}`,
              formattedDate,
            )
          }
        >
          <div>
            <p>{t('tap_to_full_screen')}</p>
            <img src={openFullIcon} alt="open full icon" />
          </div>
        </ModalOpener>
      );
    }
    case 'draw': {
      return (
        <ModalOpener
          image={answer}
          onClick={() =>
            openDrawModal(
              answer,
              `${t('show_signature_modal_title')}`,
              formattedDate,
            )
          }
        >
          <div>
            <p>{t('tap_to_full_screen')}</p>
            <img src={openFullIcon} alt="open full icon" />
          </div>
        </ModalOpener>
      );
    }

    case 'checkbox': {
      const allItemsAnswereds = answer.split('\t');
      const answerString = allItemsAnswereds
        .map(
          (itemAnswer) =>
            field.items.find((fieldItem) => fieldItem.value === itemAnswer)
              ?.label ?? itemAnswer,
        )
        .join(', ');
      return <Input>{answerString}</Input>;
    }
    default:
      return <Input>{answer}</Input>;
  }
}
