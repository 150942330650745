import { Edit } from '@mui/icons-material';
import React, { useState } from 'react';
import { AgroFab, ColorPreview, SelectBrushColor } from './styles';

export const ColorOptions = [
  {
    name: 'white',
    color: '#ffff',
  },
  {
    name: 'black',
    color: '#3E4149',
  },
  {
    name: 'green',
    color: '#00A420',
  },
  {
    name: 'orange',
    color: '#FFA800',
  },
  {
    name: 'blue',
    color: '#006DFF',
  },
  {
    name: 'red',
    color: '#F03939',
  },
];

interface IDrawColorsMenu {
  drawColor: string;
  setDrawColor: (color: string) => void;
}

export const DrawColorsMenu = ({
  drawColor,
  setDrawColor,
}: IDrawColorsMenu) => {
  const [drawColorsMenu, setDrawColorsMenu] = useState(false);
  const handleDrawColors = () => {
    if (drawColorsMenu === false) {
      setDrawColorsMenu(true);
    } else {
      setDrawColorsMenu(false);
    }
  };

  return (
    <>
      <SelectBrushColor>
        {drawColorsMenu &&
          ColorOptions.map((DrawColor) => (
            <ColorPreview
              color={DrawColor.color}
              onClick={() => setDrawColor(DrawColor.color)}
            />
          ))}
      </SelectBrushColor>
      <AgroFab
        size="small"
        onClick={() => handleDrawColors()}
        backgroundColor={drawColor}
      >
        <Edit />
      </AgroFab>
    </>
  );
};
