import { useTranslation } from 'react-i18next';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { ReactComponent as GeolocationImage } from 'assets/images/FieldNotebook/geolocation_permittion_modal.svg';
import { Typography } from '@mui/material';
import { ButtonContainer, ContentContainer } from './styles';

interface NeedGeolocationPermitionNoteProps {
  closeModal: () => void;
}

export function NeedGeolocationPermitionNote({
  closeModal,
}: NeedGeolocationPermitionNoteProps) {
  const { t } = useTranslation();

  return (
    <>
      <ContentContainer>
        <GeolocationImage />
        <Typography variant="body2" color="#000">
          {t('need_geolocation_description')}
        </Typography>
      </ContentContainer>
      <ButtonContainer>
        <Button variant="contained" onClick={closeModal}>
          {/* {t('ok')} */}
          Permitir localização
        </Button>
      </ButtonContainer>
    </>
  );
}
